import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

let userLang = (navigator.language || navigator.userLanguage).substring(0,2); 
if(userLang!="es" && userLang!="en") userLang = "en"; 

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <div>
      <Navigation data={landingPageData['Navigation-'+userLang]}/>
      <Header data={landingPageData['Header-'+userLang]} />
       {/* <Features data={landingPageData['Features-'+userLang]} />  */}
      <About data={landingPageData['About-'+userLang]} />
      <Services data={landingPageData['Services-'+userLang]} /> 
      <Gallery data={landingPageData['Gallery-'+userLang]} />
      {/* <Testimonials data={landingPageData['Testimonials-'+userLang]} /> */}
      {/* <Team data={landingPageData['Team-'+userLang]} /> */}
      <Contact data={landingPageData['Contact-'+userLang]} />
    </div>
  );
};

export default App;
